


















































import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'post-list-item': () => import('@/modules/community/common/components/post-list-item.vue'),
  },
})
export default class extends Vue {
  @Inject({}) setting!: DaoDetailSettingViewModel

  mounted() {
    this.setting.fetchReportedPost()
  }
}
